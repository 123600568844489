import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import spinner from "./images/spinner.gif";
import PrivateRoute from "./PrivateRoute";

const Login = lazy(() => import('./components/auth/Login'));
const Profile = lazy(() => import('./components/auth/Profile'));
const Orders = lazy(() => import('./components/auth/Orders'));
const PaymentHistory = lazy(() => import('./components/auth/PaymentHistory'));
const Address = lazy(() => import('./components/auth/Address'));
const Groups = lazy(() => import('./components/auth/Groups'));

const Search = lazy(() => import('./components/pages/Search'));
const Home = lazy(() => import('./components/pages/Home'));
const Deals = lazy(() => import('./components/pages/Deals'));
const Menu = lazy(() => import('./components/pages/Menu'));
const Carts = lazy(() => import('./components/pages/Carts'));
const PlaceOrder = lazy(() => import('./components/pages/PlaceOrder'));
const PrintOrder = lazy(() => import('./components/pages/PrintOrder'));
const PrintKOT = lazy(() => import('./components/pages/PrintKOT'));
const Error = lazy(() => import('./components/pages/Error'));
const ThankYou = lazy(() => import('./components/pages/ThankYou'));
const Call = lazy(() => import('./components/pages/Call'));
const DisplayOrder = lazy(() => import('./components/pages/OrderList'));
const DisplayZomatoOrder = lazy(() => import('./components/pages/DisplayZomatoOrder'));
const DisplaySwiggyOrder = lazy(() => import('./components/pages/DisplaySwiggyOrder'));
// const DisplayOrder2 = lazy(() => import('./components/pages/DisplayOrder2'));
const FailedOrder = lazy(() => import('./components/pages/FailedOrder'));
const DownloadApp = lazy(() => import('./components/pages/DownloadApp'));
// const RawPage = lazy(() => import('./components/pages/RawPage'));

function App() {

    return (
        <Suspense fallback={<div className="loader-default"><img src={spinner} alt="Loading" className="loader-spinner" /></div>}>
            <Router>
                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/login" component={Login} />

                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/orders" component={Orders} />
                <PrivateRoute path="/payment-history" component={PaymentHistory} />
                <PrivateRoute path="/address" component={Address} />
                <PrivateRoute path="/groups" component={Groups} />

                <Route exact={true} path="/place-order" component={PlaceOrder} />
                <Route exact={true} path="/print-order/:id" component={PrintOrder} />
                <Route exact={true} path="/print-kot/:id" component={PrintKOT} />

                <Route exact={true} path="/search" component={Search} />
                <Route exact={true} path="/deals" component={Deals} />
                <Route exact={true} path="/menu" component={Menu} />
                <Route exact={true} path="/cart" component={Carts} />
                <Route exact={true} path="/call" component={Call} />
                <Route exact={true} path="/error" component={Error} />
                <Route exact={true} path="/thank-you/:id/:mobile" component={ThankYou} />
                <Route exact={true} path="/display-order/:unique_id" component={DisplayOrder} />
                <Route exact={true} path="/display-zomato-order/:unique_id" component={DisplayZomatoOrder} />
                <Route exact={true} path="/display-swiggy-order/:unique_id" component={DisplaySwiggyOrder} />
                {/* <Route exact={true} path="/display-order-two/:unique_id" component={DisplayOrder2} /> */}
                <Route exact={true} path="/failed-order/:unique_id" component={FailedOrder} />
                <Route exact={true} path="/app" component={DownloadApp} />

                {/* <Route exact={true} path="/privacy-policy" component={RawPage} />
                <Route exact={true} path="/terms-condition" component={RawPage} /> */}
                {/* <Route exact={true} path="/about-us" component={RawPage} /> */}

            </Router>
        </Suspense>
    );
}

export default App;
